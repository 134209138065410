import { useLanguage } from "../../hooks/useLanguage";
import TableTextCell, { ITableTextCellProps } from "./TableTextCell";

interface TableFloatCellProps<D extends object = Record<string, unknown>>
    extends ITableTextCellProps<D>,
        Pick<Intl.NumberFormatOptions, "minimumFractionDigits" | "maximumFractionDigits"> {}

const TableFloatCell = <D extends object = Record<string, unknown>>({
    value,
    minimumFractionDigits,
    maximumFractionDigits,
    ...textCellProps
}: TableFloatCellProps<D>) => {
    const language = useLanguage();
    const valueAsNumber = typeof value === "number" ? value : parseFloat(value);

    if (isNaN(valueAsNumber)) {
        return null;
    }
    const formattedValue = valueAsNumber.toLocaleString(language, { maximumFractionDigits, minimumFractionDigits });
    return <TableTextCell align="right" value={formattedValue} {...textCellProps} />;
};

export default TableFloatCell;
